
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
// import TableHeaderChange from "@/layout/header/partials/trainning_institute/TableHeaderChange.vue";
import Multiselect from '@vueform/multiselect';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'searchCourse',
  components: {
    Datatable,
    Multiselect,
    Field,
    // TableHeaderChange,
  },

  data() {
    return {
      selectedDistricts: [] as any,
      value: null,
      Tmonth: '' as any,
      Thour: '' as any,
      options: [],
      batch: {},
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourseType: [],
      selectedCourse: [],
      selectedDistrict: [],
      updatedArray: [] as any,
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Phase',
          key: 'tranche',
          sortable: true,
          width: '10vw',
          selected: true,
        },
        {
          name: 'Entity',
          key: 'entity',
          sortable: true,
          selected: true,
          width: '10vw',
        },
        {
          name: 'Entity Contract',
          key: 'entity_contract',
          sortable: true,
          width: '10vw',
        },
        // {
        //   name: "X Course Name",
        //   key: "x_course_name",
        //   sortable: true,
        //   selected: true,
        //   width: "10vw",
        // },
        {
          name: 'Course Category',
          key: 'course_category',
          sortable: true,
          selected: true,
          width: '10vw',
        },
        {
          name: 'Code',
          key: 'code',
          sortable: true,
          width: '10vw',
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'name',
          sortable: true,
          width: '10vw',
          selected: true,
        },
        {
          name: 'Course Duration Month',
          key: 'month',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Term Count',
          key: 'term',
          sortable: true,
          selected: true,
          width: '10vw',
        },
        {
          name: 'Total Training Session',
          key: 'trainning_session',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Minimum Day Per Month',
          key: 'min_day_per_month',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Total Training Days',
          key: 'day',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Grace Period',
          key: 'e_grace',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Course Duration Hour',
          key: 'hour',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Total Target Batches',
          key: 'total_target_batches',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Students Per Batch',
          key: 'student_per_batch',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Total Students',
          key: 'student',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Unit Cost Total',
          key: 'unit_cost_total',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Is Mgt',
          key: 'isMgt',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Is Advanced Course',
          key: 'advance_course',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Is Tot Course',
          key: 'isTot',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Course Objective',
          key: 'course_objective',
          sortable: true,
          width: '80vw',
        },
        {
          name: 'Entry Qualification',
          key: 'entry_qualification',
          sortable: true,
          width: '25vw',
        },
        {
          name: 'Course Sector',
          key: 'course_sector',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Stipend Flag',
          key: 'stipend_flag',
          sortable: true,
          width: '10vw',
        },
        {
          name: 'Is Reassesment Enabled',
          key: 'is_reassesment',
          sortable: true,
          width: '10vw',
        },
      ] as any,

      districtData: [] as any,
      TpartnerData: [] as any,
      TranceData: [] as any,
      CourseTypeData: [] as any,
      TInstittuteData: [] as any,
      TcourseStatus: [] as any,
      districtlabel: [] as any,
      TpartnerLabel: [] as any,
      TrancheLabel: [] as any,
      CourseLabel: [] as any,
      InstituteLabel: [] as any,
      CourseStatusLabel: [] as any,

      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      CourseTypeInfos: [] as any,
      InstituteInfos: [] as any,

      optionsTPartner: [] as any,
      optionsTranche: [] as any,
      optionsCourseType: [] as any,
      optionsInstituteList: [] as any,
      optionsCourseStatusList: [
        { value: 'running', label: 'Running' },
        { value: 'completed', label: 'Completed' },
      ] as any,
      st: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,

      isAssesd: 0,
      isCertified: 0,
      isEmployee: 0,
    };
  },
  async created() {
    await this.getAssociation();
    await this.getTranche();
    await this.getCourseTypeList();
    // await this.getallCourse();
  },
  methods: {
    // isstatusCheck(event) {
    //   if (event.target.checked) {
    //     this.isAssesd = 1;
    //   } else {
    //     this.isAssesd = 0;
    //   }
    // },
    // isCertifiedCheck(event) {
    //   if (event.target.checked) {
    //     this.isCertified = 1;
    //   } else {
    //     this.isCertified = 0;
    //   }
    // },
    // isEmployeeCheck(event) {
    //   if (event.target.checked) {
    //     this.isEmployee = 1;
    //   } else {
    //     this.isEmployee = 0;
    //   }
    // },
    resetData() {
      this.tableData = [] as any;
      this.districtData = [] as any;
      this.TranceData = [] as any;
      this.TpartnerData = [] as any;
      this.CourseTypeData = [] as any;
      this.TInstittuteData = [] as any;
      this.TcourseStatus = [
        {
          running: 0,
          completed: 0,
        },
      ] as any;

      this.TpartnerLabel = [] as any;
      this.TrancheLabel = [] as any;
      this.CourseLabel = [] as any;
      this.InstituteLabel = [] as any;
      this.CourseStatusLabel = [] as any;
      this.st = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },

    async getCourseTypeList() {
      this.load = true;
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.CourseTypeInfos = response.data.data;
          this.CourseTypeInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.type_name,
            };
            this.optionsCourseType.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          console.log(entity_id, this.TpartnerInfos);
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {});
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    changePartnerData(data) {
      this.getInstituteListData();
      this.TpartnerLabel = [];
      data.map((item, index) => {
        this.optionsTPartner.forEach((item2, index2) => {
          if (item2.value == item) {
            this.TpartnerLabel.push(item2.label);
          }
        });
      });
    },
    changeCourseStatusData(data) {
      console.log(data);
      this.CourseStatusLabel = [];
      data.map((item, index) => {
        this.optionsCourseStatusList.forEach((item2, index2) => {
          if (item2.value == item) {
            this.CourseStatusLabel.push(item2.label);
          }
        });
      });
    },
    async getInstituteListData() {
      await ApiService.get(
        'institute/filterInstitute?entity=' + this.TpartnerData
      )
        .then((response) => {
          this.InstituteInfos = [] as any;
          this.InstituteInfos = response.data.data;
          this.optionsInstituteList = [] as any;
          console.log(this.optionsInstituteList);
          this.InstituteInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.institute_name,
            };
            this.optionsInstituteList.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    changeTrancheData(data) {
      this.TrancheLabel = [];
      data.map((item, index) => {
        this.optionsTranche.forEach((item2, index2) => {
          if (item2.value == item) {
            this.TrancheLabel.push(item2.label);
          }
        });
        console.log(this.TrancheLabel);
      });
    },
    changeCourseTypeData(data) {
      this.CourseLabel = [];
      // console.log(this.optionsCourseType);
      data.map((item, index) => {
        this.optionsCourseType.forEach((item2, index2) => {
          if (item2.value == item) {
            this.CourseLabel.push(item2.label);
          }
        });
      });
      // console.log(this.CourseLabel);
    },

    changeInstituteListData(data) {
      this.InstituteLabel = [];
      // console.log(this.optionsCourseType);
      data.map((item, index) => {
        this.optionsInstituteList.forEach((item2, index2) => {
          if (item2.value == item) {
            this.InstituteLabel.push(item2.label);
          }
        });
      });
      // console.log(this.CourseLabel);
    },

    async generateCourseSearchReport() {
      this.load = true;
      await ApiService.get(
        'course/course-report?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&courseType=' +
          this.CourseTypeData +
          '&institute=' +
          this.TInstittuteData +
          '&month=' +
          this.Tmonth +
          '&hour=' +
          this.Thour +
          '&courseStatus=' +
          this.TcourseStatus
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          this.componentTableKey += 1;
          this.load = false;
        })
        .then((response) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get('entity/infos')
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
      // this.tableHeader.map((item) => {
      //   if (item.selected) {
      //     this.st.push(
      //       JSON.parse(JSON.stringify({ name: item.name, key: item.key }))
      //     );
      //   } else {
      //   }
      // });
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      web_url: Yup.string().required().label('Web Url'),
      postcode: Yup.string().required().max(4).label('Postcode'),
      registration_number: Yup.string().required().label('Registration Number'),
      registration_authority: Yup.string()
        .required()
        .label('Registration Authority'),
      telephone: Yup.number().required().min(11).label('Telephone'),
      entity_name: Yup.string().required().label('Name'),
      entity_short_name: Yup.string().required().label('Short Name'),

      employeeEmail: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label('Name'),
      designation: Yup.string().required().label('Designation'),
      gender: Yup.string().required().label('Gender'),
      mobile: Yup.number().required().min(11).label('Mobile'),
      dob: Yup.string().required().label('Date of Birth'),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .label('Password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password didn't match!")
        .label('Confirm Password'),
    });
    return {
      AssociationSchema,
    };
  },
});
